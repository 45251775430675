import React from "react";

const Quimica = () => {
  return (
    <div>
      <h1>Curso de Química</h1>
      <div>
        <h2>Video 1: Hidrógeno</h2>
        <video width="560" height="315" controls>
        <source src="/videos/BurbujasExplosivas.mp4" type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      </div>
      <div>
        <h2>Video 2: Reloj Electroquímico</h2>
        <video width="560" height="315" controls>
        <source src="/videos/Reloj Electroquimico.mp4" type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      </div>
    </div>
  );
};

export default Quimica;
