import React from "react";

const Mecanica = () => {
  return (
    <div className="video-container">
      <h2>Mecánica</h2>
      <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/VIDEO_ID_3" 
        title="Video de Mecánica"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Mecanica;
