import React from "react";
import './Matematicas.css';

const Matematicas = () => {
  return (
    <div className="matematicas-container">
      <h1 className="title">Curso de Matemáticas</h1>
      <p className="description">
        Explora los temas esenciales de matemáticas a través de este temario detallado.
      </p>
      
      <h2 className="subtitle">Temario</h2>
      <ol className="temario-list">
        <li>
          <span className="topic">Introducción a la plataforma</span>
          <ol className="sublist">
            <li>Introducción a Desmos</li>
            <li>Herramientas</li>
          </ol>
        </li>
        <li>
          <span className="topic">Aplicaciones al Álgebra</span>
          <ol className="sublist">
            <li>Resolución de ecuaciones lineales y cuadráticas</li>
            <li>Sistemas de ecuaciones</li>
          </ol>
        </li>
        <li>
          <span className="topic">Aplicaciones a la Trigonometría</span>
          <ol className="sublist">
            <li>Funciones trigonométricas</li>
            <li>Resolución de triángulos</li>
          </ol>
        </li>
        <li>
          <span className="topic">Aplicaciones a la Geometría Analítica</span>
          <ol className="sublist">
            <li>Figuras geométricas</li>
            <li>La recta</li>
            <li>Las cónicas</li>
          </ol>
        </li>
        <li>
          <span className="topic">Aplicaciones al Cálculo Diferencial</span>
          <ol className="sublist">
            <li>Límites y continuidad</li>
            <li>Derivadas</li>
          </ol>
        </li>
        <li>
          <span className="topic">Aplicaciones al Cálculo Integral</span>
          <ol className="sublist">
            <li>Concepto de integral</li>
            <li>Cálculo de áreas</li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default Matematicas;
