import React from "react";

const Electromagnetismo = () => {
  return (
    <div className="video-container">
      <h2>Electromagnetismo</h2>
      <video width="560" height="315" controls>
        <source src="/videos/Calculo_de_Resistencia.mp4" type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
    </div>  
  );
};

export default Electromagnetismo;
