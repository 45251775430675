// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/HeroSection.css */

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 80px; /* Espacio para evitar que el navbar lo cubra */
    background-color: #ECF0F1; /* Fondo blanco */
    color: #2C3E50; /* Azul Marino */
}

.hero-text {
    padding: 20px;
    max-width: 800px;
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: bold;
}

.hero-text p {
    font-size: 1.2rem;
    color: #7F8C8D; /* Gris oscuro */
}

.hero-video-container {
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.hero-video-container video {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeroSection.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB,EAAE,+CAA+C;IAClE,yBAAyB,EAAE,iBAAiB;IAC5C,cAAc,EAAE,gBAAgB;AACpC;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,cAAc,EAAE,gBAAgB;AACpC;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,+BAA+B;IAC/B,yCAAyC;IACzC,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["/* src/components/HeroSection.css */\r\n\r\n.hero-section {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    text-align: center;\r\n    padding-top: 80px; /* Espacio para evitar que el navbar lo cubra */\r\n    background-color: #ECF0F1; /* Fondo blanco */\r\n    color: #2C3E50; /* Azul Marino */\r\n}\r\n\r\n.hero-text {\r\n    padding: 20px;\r\n    max-width: 800px;\r\n}\r\n\r\n.hero-text h1 {\r\n    font-size: 3rem;\r\n    font-weight: bold;\r\n}\r\n\r\n.hero-text p {\r\n    font-size: 1.2rem;\r\n    color: #7F8C8D; /* Gris oscuro */\r\n}\r\n\r\n.hero-video-container {\r\n    margin-top: 20px;\r\n    width: 100%;\r\n    max-width: 800px;\r\n    overflow: hidden;\r\n    transition: transform 0.3s ease;\r\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);\r\n    border-radius: 10px;\r\n}\r\n\r\n.hero-video-container video {\r\n    width: 100%;\r\n    height: auto;\r\n    border-radius: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
