// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */

/* Estilos globales */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #2C3E50; /* Azul Marino */
  color: #ECF0F1; /* Blanco */
}

.App {
  text-align: center;
}

/* Ajusta el espaciado del contenido principal para evitar el solapamiento del Navbar */
.main-content {
  padding-top: 60px; /* La altura del Navbar */
}

/* Estilo para la sección de bienvenida */
.welcome-section {
  padding: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB,qBAAqB;AACrB;EACE,SAAS;EACT,8BAA8B;EAC9B,yBAAyB,EAAE,gBAAgB;EAC3C,cAAc,EAAE,WAAW;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA,uFAAuF;AACvF;EACE,iBAAiB,EAAE,yBAAyB;AAC9C;;AAEA,yCAAyC;AACzC;EACE,aAAa;AACf","sourcesContent":["/* src/App.css */\n\n/* Estilos globales */\nbody {\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #2C3E50; /* Azul Marino */\n  color: #ECF0F1; /* Blanco */\n}\n\n.App {\n  text-align: center;\n}\n\n/* Ajusta el espaciado del contenido principal para evitar el solapamiento del Navbar */\n.main-content {\n  padding-top: 60px; /* La altura del Navbar */\n}\n\n/* Estilo para la sección de bienvenida */\n.welcome-section {\n  padding: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
