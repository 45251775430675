// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.temario-menu {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .temario-menu li {
    margin: 10px 0;
  }
  
  .temario-menu li a {
    text-decoration: none;
    font-size: 1rem;
    color: #93dde7;
    border: 1px solid #93dde7;
    padding: 10px 15px;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .temario-menu li a:hover {
    background-color: #93dde7;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Fisica.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qEAAqE;EACvE;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":[".temario-menu {\n    list-style: none;\n    padding: 0;\n    margin: 20px 0;\n  }\n  \n  .temario-menu li {\n    margin: 10px 0;\n  }\n  \n  .temario-menu li a {\n    text-decoration: none;\n    font-size: 1rem;\n    color: #93dde7;\n    border: 1px solid #93dde7;\n    padding: 10px 15px;\n    border-radius: 5px;\n    display: inline-block;\n    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;\n  }\n  \n  .temario-menu li a:hover {\n    background-color: #93dde7;\n    color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
