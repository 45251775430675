// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import LoginPage from './components/LoginPage';
import Footer from './components/Footer';
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import AboutPage from './components/AboutPage';
import ContactPage from "./components/ContactPage";
import RegistroPage from './components/RegistroPage';
import ReestablecerPage from './components/ReestablecerPage';
import Matematicas from './components/Matematicas';
import Fisica from './components/Fisica';
import Quimica from './components/Quimica';
import Biologia from './components/Biologia';
import Electromagnetismo from './components/Electromagnétismo'
import Optica from './components/Optica'
import Mecanica from './components/Mecanica'
import Hidraulica from './components/Hidraulica'

import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar /> {/* Barra de navegación fija */}

                {/* Contenedor principal para las páginas */}
                <div className="main-content">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <div>
                                    <Header />
                                    <HeroSection />
                                    <div className="welcome-section">
                                        <h1>¡Bienvenido a Stem4All!</h1>
                                        <p>Este es el comienzo de tu aplicación en React.</p>
                                    </div>
                                </div>
                            }
                        />
                        <Route path="/login" element={<LoginPage />} /> {/* Página de inicio de sesión */}
                        <Route path="/terms" element={<TermsAndConditionsPage />} /> {/* Términos y condiciones */}
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/registro" element={<RegistroPage />} />
                        <Route path="/reestablecer" element={<ReestablecerPage />} />
                        <Route path="/fisica" element={<Fisica />} />
                        <Route path="/matematicas" element={<Matematicas />} />
                        <Route path="/quimica" element={<Quimica />} />
                        <Route path="/biologia" element={<Biologia />} />
                        <Route path="/electromagnetismo" element={<Electromagnetismo />} />
                        <Route path="/optica" element={<Optica />} />
                        <Route path="/mecanica" element={<Mecanica />} />
                        <Route path="/hidraulica" element={<Hidraulica />} />

                    </Routes>
                </div>

                <Footer />
            </div>
        </Router>
    );
}

export default App;
