import React from "react";

const Biologia = () => {
  return (
    <div>
      <h1>Curso de Biología</h1>
      <p>Descubre cómo funciona la vida desde un punto de vista científico.</p>
      <div>
        <h2>Video 1: Introducción a la Biología</h2>
        <iframe
          src="https://drive.google.com/file/d/ID_DEL_VIDEO_BIOLOGIA/preview"
          width="640"
          height="480"
          allow="autoplay"
          title="Introducción a la Biología"
        ></iframe>
      </div>
    </div>
  );
};

export default Biologia;
