// src/components/Header.js
import React from 'react';

import './Header.css';
function Header() {
    return (
        <header className="header">
            <img src="/educacion.svg" alt="Header 1" className="header-image" />
            <img src="/ipn.svg" alt="Header 2" className="header-image" />
        </header>

    );
}

export default Header;
