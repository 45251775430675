import React from "react";
import { Link } from "react-router-dom";
import "./Fisica.css";

const Fisica = () => {
  return (
    <div className="container">
      <h1>Curso de Física</h1>
      <h2>Temario</h2>
      <ul className="temario-menu">
        <li>
          <Link to="/electromagnetismo">Electromagnetismo</Link>
        </li>
        <li>
          <Link to="/optica">Óptica</Link>
        </li>
        <li>
          <Link to="/mecanica">Mecánica</Link>
        </li>
        <li>
          <Link to="/hidraulica">Hidráulica</Link>
        </li>
      </ul>
    </div>
  );
};

export default Fisica;
