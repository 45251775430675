// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff0df; /* Fondo opcional */
    padding: 10px;
  }
  
  .header-image {
    width: 20%;
    height: auto; /* Mantener la proporción de la imagen */
    object-fit: cover; /* Ajusta la imagen para que llene el contenedor y recorte el exceso */
  }
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB,EAAE,mBAAmB;IAC9C,aAAa;EACf;;EAEA;IACE,UAAU;IACV,YAAY,EAAE,wCAAwC;IACtD,iBAAiB,EAAE,sEAAsE;EAC3F","sourcesContent":[".header {\n    width: 100vw;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #fff0df; /* Fondo opcional */\n    padding: 10px;\n  }\n  \n  .header-image {\n    width: 20%;\n    height: auto; /* Mantener la proporción de la imagen */\n    object-fit: cover; /* Ajusta la imagen para que llene el contenedor y recorte el exceso */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
